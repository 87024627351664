//
//
//
//
//
//

import { textStyleProps, getTextClasses } from '@/helpers/text-styles';
import { computed } from '@nuxtjs/composition-api';
const BASE_CLASS = 's-banner-content';

export default {
  name: 'SBannerContent',
  props: {
    text: {
      type: String,
      required: false
    },
    fontSizeMobile: {
      type: String,
      required: false
    },
    fontSizeTablet: {
      type: String,
      required: false
    },
    fontSizeDesktop: {
      type: String,
      required: false
    },
    ...textStyleProps('content')
  },
  setup(props) {
    const containerClasses = computed(() => [BASE_CLASS, ...getTextClasses(props).value]);

    const fontCSSVar = computed(() => ({
      '--font-size-mobile': `${props.fontSizeMobile}px`,
      '--font-size-tablet': `${props.fontSizeTablet}px`,
      '--font-size-desktop': `${props.fontSizeDesktop}px`
    }));

    return {
      containerClasses,
      fontCSSVar
    };
  }
};
