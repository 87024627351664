import { computed, getCurrentInstance } from '@nuxtjs/composition-api';

export const getCssFlagsProps = () => ({
  cssFlags: {
    type: Object,
    required: false,
    default: () => ({
      sm: [],
      md: [],
      lg: [],
      xl: []
    })
  }
});

export const getCssFlagsClasses = (props, baseClass) => {
  const instance = getCurrentInstance();
  const currentCssFlags = computed(() => {
    return props.cssFlags[instance.proxy.$screen.breakpoint];
  });
  return (currentCssFlags.value || []).map(f => `${baseClass}--${f}`);
};

export const cssFlagsStoryArgs = (options) => ({
  cssFlagsSm: {
    name: 'CSS Flags Sm',
    control: {
      type: 'check',
      options
    }
  },
  cssFlagsMd: {
    name: 'CSS Flags Md',
    control: {
      type: 'check',
      options
    }
  },
  cssFlagsLg: {
    name: 'CSS Flags Lg',
    control: {
      type: 'check',
      options
    }
  },
  cssFlagsXl: {
    name: 'CSS Flags Xl',
    control: {
      type: 'check',
      options
    }
  }
});

export const storyCssFlagsComputed = context => computed(() => ({
  sm: context.cssFlagsSm || [],
  md: context.cssFlagsMd || [],
  lg: context.cssFlagsLg || [],
  xl: context.cssFlagsXl || []
}));
