//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Spinner',
  props: {
    theme: {
      type: String,
      default: 'dark'
    }
  }
};
