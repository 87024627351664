//
//
//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';

const BASE_CLASS = 'n-section';
export default {
  props: {
    headline: {
      type: String,
      required: false,
      default: null
    },
    headlineElement: {
      type: String,
      default: 'h2',
      validator: (v) => ['h1', 'h2', 'h3', 'h4'].includes(v)
    },
    gridColumns: {
      type: Number,
      default: 12,
      required: false
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => []
    },
    background: {
      type: String | Object,
      required: false
    },
    columnsGap: {
      type: Array,
      // sm, md, lg
      default: () => [[20], [40], [60]],
      required: false
    }
  },
  setup(props) {
    const { $img } = useContext();
    const getColumnsGap = (bp) => {
      const [sm, md, lg] = props.cssFlags.includes('no-grid-gap') ? [[0], [0], [0]] : props.columnsGap;
      const bps = { sm, md, lg };
      return `${bps[bp][0]}px ${bps[bp][1] || bps[bp][0]}px`;
    };

    const componentsCssVars = computed(() => {
      const cssVars = {
        '--columns': props.gridColumns,
        '--columns-gap-sm': getColumnsGap('sm'),
        '--columns-gap-md': getColumnsGap('md'),
        '--columns-gap-lg': getColumnsGap('lg'),
        '--background-color': (props.background?.color ?? 'transparent') || 'transparent'
      };

      if (String(props.background?.mobileImage ?? '').length) {
        cssVars['--background-image-mobile'] = `url("${$img(
          props.background.mobileImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-mobile-size'] = props.background.mobileImageSize || 'cover';
      }

      if (String(props.background?.desktopImage ?? '').length) {
        cssVars['--background-image-desktop'] = `url("${$img(
          props.background.desktopImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-desktop-size'] = props.background.desktopImageSize || 'cover';
      }
      return cssVars;
    });

    const containerClasses = computed(() => {
      const classes = [BASE_CLASS, ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)];
      if (String(props.background?.mobileImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-mobile`);
      }
      if (String(props.background?.desktopImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-desktop`);
      }
      return classes;
    });

    return {
      componentsCssVars,
      containerClasses
    };
  }
};
