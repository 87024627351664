//
//
//
//
//
//
//
//

export default {
  name: 'WebpImage',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const getWebPImage = (image) => {
      const extension = image.split('.').pop();
      return image.replace('.' + extension, '.webp');
    };

    const getImageMimeType = (image) => {
      const extension = image.split('.').pop();
      switch (extension) {
        case 'png':
          return 'image/png';
        case 'jpeg':
        case 'jpg':
          return 'image/jpeg';
        case 'webp':
          return 'image/webp';
      }
    };

    return {
      getWebPImage,
      getImageMimeType
    };
  }
};
