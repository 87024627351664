//
//
//
//
//
//
//
//
//

/* Chips are compact elements that represent an input, attribute, or action. */

export default {
  props: {
    color: {
      type: String,
      default: 'var(--color-secondary)'
    },
    border: {
      type: String,
      default: 'none'
    },
    borderRadius: {
      type: String,
      default: '2px'
    },
    textColor: {
      type: String,
      default: 'currentColor'
    },
    textTransform: {
      type: String,
      default: 'none'
    },
    fontFamily: {
      type: String,
      default: 'var(--ff-primary)'
    }
  }
};
