//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
export default {
  name: 'SList',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => ['content First', 'content Second', 'content Third']
    },
    prettyBullets: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const containerClasses = computed(() => {
      return {
        'n-list--pretty-bullets': props.prettyBullets === true
      };
    });
    return {
      containerClasses
    };
  }
};
