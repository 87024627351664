//
//
//
//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-section-column';
export default {
  props: {
    columnSizes: {
      type: Array,
      required: false,
      // Mobile, tablet, desktop
      default: () => [12, 12, 12]
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    minHeight: {
      type: String,
      required: false,
      default: '0'
    },
    background: {
      type: String | Object,
      required: false
    },
    padding: {
      type: String | Object,
      required: false
    },
    margin: {
      type: String | Object,
      required: false
    },
    badge: {
      type: Object | String,
      required: false
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => []
    },
    borderRadius: {
      type: String | Object,
      required: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      $img,
      app: { $screen }
    } = useContext();
    const cssVars = computed(() => {
      const vars = {
        '--column-size--sm': props.columnSizes[0],
        '--column-size--md': props.columnSizes[1],
        '--column-size--lg': props.columnSizes[2],
        '--max-width': props.maxWidth,
        '--min-height': props.minHeight,
        '--background': (props.background?.color ?? 'transparent') || 'transparent',
        '--padding-sm': props.padding?.result?.mobile ?? '',
        '--padding-md': props.padding?.result?.tablet ?? '',
        '--padding-lg': props.padding?.result?.desktop ?? '',
        '--margin-sm': props.margin?.result?.mobile ?? '',
        '--margin-md': props.margin?.result?.tablet ?? '',
        '--margin-lg': props.margin?.result?.desktop ?? '',
        '--border-radius': props.borderRadius ?? '0px',
        '--badge-bg-color': props.badge?.background ?? 'transparent',
        '--badge-width': props.badge?.width ? `${props.badge.width}px` : '0px'
      };

      if (props.badge?.position?.split(' ')?.length > 1) {
        let offset = Number(props.badge?.offset || 0);

        if (!$screen.md) {
          offset /= 2;
        }

        const position =
          props.badge?.offset?.length > 0
            ? props.badge.position.replace(/0/g, `${offset}px`).split(' ')
            : props.badge.position.split(' ');

        const [top, right, bottom, left] = position;
        vars['--badge-position-top'] = top;
        vars['--badge-position-right'] = right;
        vars['--badge-position-bottom'] = bottom;
        vars['--badge-position-left'] = left;
      }

      if (String(props.background?.mobileImage ?? '').length) {
        cssVars['--background-image-mobile'] = `url("${$img(
          props.background.mobileImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-mobile-size'] = props.background.mobileImageSize || 'cover';
      }

      if (String(props.background?.desktopImage ?? '').length) {
        cssVars['--background-image-desktop'] = `url("${$img(
          props.background.desktopImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-desktop-size'] = props.background.desktopImageSize || 'cover';
      }

      return vars;
    });
    const containerClasses = computed(() => {
      const classes = [BASE_CLASS, ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)];
      if (String(props.background?.mobileImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-mobile`);
      }
      if (String(props.background?.desktopImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-desktop`);
      }
      return classes;
    });

    const badgeText = computed(() => props.badge?.text ?? '');

    return {
      cssVars,
      containerClasses,
      badgeText
    };
  }
};
