//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  props: {
    type: {
      type: String,
      default: 'separator',
      validator: (v) => ['separator', 'merger'].includes(v)
    },
    size: {
      type: String,
      default: 'medium',
      validator: (v) => ['small', 'medium', 'large', 'extra-large'].includes(v)
    },
    borders: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const cssVars = computed(() => props.borders.map((cssClass) => `n-separator--${cssClass}`));
    const cssClass = computed(() => {
      if (!cssVars.value || !cssVars.value?.length) return '';

      return cssVars.value?.toString()?.replaceAll(',', ' ') ?? '';
    });

    return {
      cssVars,
      cssClass
    };
  }
};
