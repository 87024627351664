//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'SBadge',
  props: {
    headLine: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    },
    color: {
      type: Object,
      default: () => {}
    },
    border: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const containerCssVars = computed(() => {
      const cssVars = {};

      if (props.color && String(props.color?.textColor).length) {
        cssVars['--button-text-color'] = props.color.textColor;
      }

      if (props.color && String(props.color?.backgroundColor).length) {
        cssVars['--button-background-color'] = props.color.backgroundColor;
      }

      return cssVars;
    });
    return {
      containerCssVars
    };
  }
};
