//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'ValidationMessage',
  props: {
    validations: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const getValidations = computed(() => {
      return props.validations.filter((validation) => validation.condition);
    });

    return {
      getValidations
    };
  }
};
