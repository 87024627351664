import TEXT_SHADOWS from './constants/TEXT_SHADOWS.json';
import TEXT_COLORS from './constants/TEXT_COLORS.json';
import TEXT_STYLES from './constants/TEXT_STYLES.json';
import TEXT_HEADINGS_STYLES from './constants/TEXT_HEADINGS_STYLES.json';
import { computed } from '@nuxtjs/composition-api';

export const textStyleProps = (type = 'content') => ({
  textStyle: {
    type: String,
    required: false,
    default: type === 'content' ? 'body' : 'display'
  },
  textColor: {
    type: String,
    required: false,
    default: null
  },
  textShadow: {
    type: String,
    required: false,
    default: null
  }
});

export const getTextClasses = props => computed(() => {
  const classes = [];
  if (props.textStyle) classes.push(`text-style-${props.textStyle}`);
  if (props.textColor) classes.push(`text-color-${props.textColor}`);
  if (props.textShadow) classes.push(`text-${props.textShadow}`);

  return classes;
});

export const textStyleStoryArgs = (type = 'content') => ({
  textShadow: {
    name: 'Text Shadow',
    control: {
      type: 'select',
      options: TEXT_SHADOWS
    }
  },
  textStyle: {
    name: 'Text Style',
    control: {
      type: 'select',
      options: type === 'heading' ? TEXT_HEADINGS_STYLES : TEXT_STYLES
    }
  },
  textColor: {
    name: 'Text Color',
    control: {
      type: 'select',
      options: TEXT_COLORS
    }
  }
});
