import { render, staticRenderFns } from "./SHtml.vue?vue&type=template&id=6581ffdb&scoped=true"
import script from "./SHtml.vue?vue&type=script&lang=js"
export * from "./SHtml.vue?vue&type=script&lang=js"
import style0 from "./SHtml.vue?vue&type=style&index=0&id=6581ffdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6581ffdb",
  null
  
)

export default component.exports