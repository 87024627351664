//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useCategory } from '@odyssey/realtainment';
import { computed, ref } from '@nuxtjs/composition-api';
export default {
  name: 'CityList',
  components: {
    CityAndDateSelect: () =>
      import(
        /* webpackChunkName: "chunk-ui-molecules-selects" */ '@/components/UI/molecules/selects/CityAndDateSelect'
      ),
    CaretDownIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--caret-down.svg?inline')
  },
  setup() {
    const { categories } = useCategory();

    const shouldShowCitySelectionModal = ref(false);
    const highlightedCities = [
      'berlin',
      'hamburg',
      'koeln',
      'stuttgart',
      'muenchen',
      'frankfurt',
      'braunschweig',
      'duesseldorf',
      'leipzig',
      'nuernberg'
    ];

    const sortedCategories = computed(() => {
      const sorted = [...categories.value]
        // sort highlighted cities to the top and keep order according to highlightedCities array
        .sort((a, b) => {
          if (highlightedCities.includes(a.slug) && highlightedCities.includes(b.slug)) {
            return highlightedCities.indexOf(a.slug) - highlightedCities.indexOf(b.slug);
          } else if (highlightedCities.includes(a.slug)) {
            return -1;
          } else if (highlightedCities.includes(b.slug)) {
            return 1;
          } else {
            return 0;
          }
        });

      sorted
        // insert a divider after the first highlightedCities
        .splice(highlightedCities.length, 0, { divider: true, includeInMenu: true, productCount: 1 });

      return sorted;
    });

    function handleOnClick(slug) {
      if (process.client && window?.ga) {
        window.ga('gaTracker.send', 'event', 'conversion', 'citiesblock', slug);
      }
    }

    function closeCitySelectionModal() {
      shouldShowCitySelectionModal.value = false;
    }

    function openCitySelectionModal() {
      shouldShowCitySelectionModal.value = true;
    }

    return {
      sortedCategories,
      handleOnClick,
      shouldShowCitySelectionModal,
      closeCitySelectionModal,
      openCitySelectionModal
    };
  }
};
