//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, nextTick } from '@nuxtjs/composition-api';

export default {
  props: {
    thumbnailSrc: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    external: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const videoRef = ref(null);
    const wasClicked = ref(false);
    const containerClasses = computed(() => ({
      'n-video-embed--not-clicked': wasClicked.value === false
    }));

    async function onClick() {
      wasClicked.value = true;

      if (!props.external && videoRef.value) {
        await nextTick();
        videoRef.value?.play();
      }
    }

    return {
      wasClicked,
      containerClasses,
      onClick,
      videoRef
    };
  }
};
