//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-layout-row';
export default {
  props: {
    background: {
      type: String | Object,
      required: false
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => []
    },
    hideOnBreakpoint: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const { $img } = useContext();
    const containerCssVars = computed(() => {
      const cssVars = {
        '--row-background': (props.background?.color ?? 'transparent') || 'transparent'
      };

      if (String(props.background?.mobileImage ?? '').length) {
        cssVars['--background-image-mobile'] = `url("${$img(
          props.background.mobileImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-mobile-size'] = props.background.mobileImageSize || 'cover';
      }

      if (String(props.background?.desktopImage ?? '').length) {
        cssVars['--background-image-desktop'] = `url("${$img(
          props.background.desktopImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-desktop-size'] = props.background.desktopImageSize || 'cover';
      }
      return cssVars;
    });
    const containerClasses = computed(() => {
      const classes = [
        BASE_CLASS,
        `${BASE_CLASS}--hide-on`,
        ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`),
        ...props.hideOnBreakpoint.map((f) => `${BASE_CLASS}--hide-on--${f}`)
      ];
      if (String(props.background?.mobileImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-mobile`);
      }
      if (String(props.background?.desktopImage ?? '').length) {
        classes.push(`${BASE_CLASS}--background-image-desktop`);
      }
      return classes;
    });

    return {
      containerCssVars,
      containerClasses
    };
  }
};
