//
//
//
//

export default {
  name: 'SAnchor',
  props: {
    name: {
      type: String,
      default: null,
      required: false
    }
  }
};
