//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { computed } from '@nuxtjs/composition-api';
import { textStyleProps, getTextClasses } from '@/helpers/text-styles';
import { getCssFlagsProps, getCssFlagsClasses } from '@/helpers/css-flags';
const BASE_CLASS = 'n-content';

export default {
  name: 'SContent',
  props: {
    text: {
      type: String,
      required: false,
      default: null
    },
    ...getCssFlagsProps(),
    ...textStyleProps('content')
  },
  setup (props) {
    const containerClasses = computed(() => [
      BASE_CLASS,
      ...getCssFlagsClasses(props, BASE_CLASS),
      ...getTextClasses(props).value
    ]);

    return {
      containerClasses
    };
  }
};
