//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-html';
const SUPPORTED_CSS_FLAGS = ['is-centered'];

export default {
  name: 'SHtml',
  props: {
    html: {
      type: String,
      required: false,
      default: null
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => [],
      validator: flags => flags.filter(f => SUPPORTED_CSS_FLAGS.includes(f)).length === flags.length
    }
  },
  setup (props) {
    const containerClasses = computed(() => [BASE_CLASS, ...props.cssFlags.map(f => `${BASE_CLASS}--${f}`)]);

    return {
      containerClasses
    };
  }
};
