












export default {
  props: {
    socialLinks: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const getSocialLinkCssClass = (item) => {
      switch (item.type) {
        case 'facebook':
          return ['icon-fb-grey'];
        case 'instagram':
          return ['icon-ig-grey'];
      }
    };
    return {
      getSocialLinkCssClass
    };
  }
};
